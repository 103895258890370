<template v-if="isMobile">
  <button class="burger" ref="burger" :class="{ '-close': menuOpen, '-drag': burger.drag }" @touchstart="dragBurger" @click="dragBurger" :aria-expanded="menuOpen ? 'true' : 'false'" aria-controls="header-site" :aria-label="menuOpen ? $t('Close menu') : $t('Open menu')" type="button">
    <span class="burger-line"></span>
    <span class="burger-line"></span>
    <span class="burger-line"></span>
  </button>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  name: 'Burger',
  mixins: [ReadyHook],
  data () {
    return {
      burger: {
        drag: false,
        pos1: 0,
        pos2: 0,
        pos3: 0,
        pos4: 0,
        posInitX: 0,
        posInitY: 0,
        touchTimer: 0
      }
    }
  },
  computed: {
    menuOpen () {
      return this.$store.state['zenith-ui'].menu.open
    }
  },
  methods: {
    dragBurger (e) {
      e.preventDefault()
      this.burger.touchTimer = Date.now()
      if (e.type === 'touchstart') {
        this.burger.posInitX = this.burger.pos3 = e.targetTouches[0].clientX
        this.burger.posInitY = this.burger.pos4 = e.targetTouches[0].clientY
        document.ontouchmove = this.elMove
        document.ontouchend = this.closeDragElement
        this.burger.drag = true
      } else {
        this.headerEvent()
      }
    },
    elMove (e) {
      e.preventDefault()
      // calculate the new cursor position:
      this.burger.pos1 = this.burger.pos3 - e.targetTouches[0].clientX
      this.burger.pos2 = this.burger.pos4 - e.targetTouches[0].clientY
      this.burger.pos3 = e.targetTouches[0].clientX
      this.burger.pos4 = e.targetTouches[0].clientY
      // set the element's new position:
      this.$el.style.left = (this.$el.offsetLeft - this.burger.pos1) + 'px'
      this.$el.style.top = (this.$el.offsetTop - this.burger.pos2) + 'px'
    },
    closeDragElement () {
      if ((Date.now() - this.burger.touchTimer <= 300) && Math.abs(this.burger.pos3 - this.burger.posInitX) <= 5 && Math.abs(this.burger.pos4 - this.burger.posInitY) <= 5) {
        this.headerEvent()
      }
      document.ontouchend = null
      document.ontouchmove = null
      this.burger.touchTimer = 0
      this.burger.drag = false
    },
    headerEvent () {
      this.$store.dispatch('zenith-ui/closeSearch')
      this.$store.dispatch('zenith-ui/toggleMenu')
      this.$el.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.burger {
  display: none;
  z-index: 101;
  left: 1rem;
  width: 1rem;
  height: 1.8rem;
  cursor: move;
  background: transparent;
  border: unset;
  transition: transform 0.2s ease-in-out;

  @include bp(max md) {
    display: flex;
    position: absolute;
    outline: none;
    top: 4rem;
    left: 2rem;
  }

  .burger-line {
    display: block;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-top: -.4rem;
    width: 2rem;
    height: .2rem;
    background: var(--zenith-theme-primary);
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out, margin 0.2s 0.2s ease-in-out;
    &:nth-child(1){
      top: 10px;
    }
    &:nth-child(3){
      top: 22px;
    }
  }

  &.-drag {
    transform: scale(1.5);
    transition: transform 0.2s 0.1s ease-in-out;
  }

  &.-close {
    height: 5rem;
    top: 4rem;

    .burger-line {
      background: var(--zenith-theme-primary);
      margin: 0;
      height: .1rem;
      &:nth-child(1){
        top: 18px;
        background: var(--zenith-theme-primary);
        transform: translate(-50%, -50%) rotate(45deg);
        transition: transform 0.2s 0.2s ease-in-out, margin 0.2s ease-in-out;
      }
      &:nth-child(2){
        display: none;
      }
      &:nth-child(3){
        top: 18px;
        background: var(--zenith-theme-primary);
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: transform 0.2s 0.2s ease-in-out, margin 0.2s ease-in-out;
      }

    }
  }
}

.header.-global.-transparent.-scroll-top {
    .burger {
      .burger-line {
        background: var(--zenith-white);
      }
    }
  &.-text-white {
    .burger {
      .burger-line {
        background: var(--zenith-white);
      }
    }
  }
  &.-open.-text-white {
    .burger {
      .burger-line {
        background: var(--zenith-theme-primary);
      }
    }
  }
  &.-menu-open {
    .burger {
      .burger-line {
        background: var(--zenith-theme-primary);
      }
    }
  }
  .burger.-close {
      .burger-line {
        background: var(--zenith-theme-primary);
      }
    }
}
</style>
