<template>
  <span class="counter" aria-live="polite" v-html="value + ' <span class=\'a11y-sr-only\'>' + trad + '</span>'" :class="{ '-reverse' : reverse, '-active': active }"></span>
</template>

<script>
export default {
  name: 'Counter.vue',
  props: ['value', 'reverse', 'trad', 'active']
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.counter {
  display: inline-block;
  vertical-align: middle;
  min-width: 1.4rem;
  min-height: 1.4rem;
  padding-top: .2rem;
  padding-left: .2rem;
  padding-right: .2rem;
  text-align: center;
  color: var(--zenith-white);
  font-size: em(10);
  line-height: 1rem;
  border-radius: 16px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--zenith-theme-primary);
  background-color: var(--zenith-theme-primary);

  &.-active {
    opacity: 1;
  }

  &.-reverse {
    color: var(--zenith-theme-primary);
    background: var(--zenith-white);
    border-color: var(--zenith-white);
  }
}

</style>
