<template>
  <form
    novalidate
    class="form search"
    role="search"
    @submit.prevent="submit"
    :class="{'-active': route === 'search'}"
    :aria-hidden="aria ? 'false' : 'true'"
  >
    <input
      ref="input"
      id="search"
      type="search"
      v-model="searchText"
      :placeholder="$t('Search')"
      :aria-label="$t('Search on website')"
      @focus="focusSearch"
      :tabindex="isMobile && !menuOpen ? -1 : 0"
    />
    <button :aria-label="$t('Submit')">
      <i class="icon-loupe"></i>
    </button>
    <div class="suggest" v-bsl="searchOpen" v-if="route !== 'search'">
      <div class="container">
        <i>{{ $t('Suggestions') }}</i>
        <ul class="collections row">
          <li v-for="(collection, key) in collections" class="collection" :key="key">
            <collection-item :collection="collection" @close="$emit('close')"></collection-item>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapActions } from 'vuex'
import { getRouteName } from '../utilities'
// import CollectionItem from './CollectionItem'

export default {
  name: 'Search',
  props: ['collections', 'aria'],
  components: {
    CollectionItem: () => import(/* webpackChunkName: "collection-item-cpt" */ './CollectionItem')
  },
  data() {
    return {
      delay: null,
      toggleActive: false,
      searchText: ''
    }
  },
  methods: {
    ...mapActions('search', ['search']),
    keyup(e) {
      if (e.keyCode === 27) {
        this.$emit('close')
      }
    },
    trapFocus(e) {
      if (!(this.$el === e.target || this.$el.contains(e.target)) && (!e.target.classList || !e.target.classList.contains('search'))) {
        this.$emit('close')
        this.$refs.input.blur()
      } else if (this.menuOpen && this.currentSection !== null) {
        this.$store.dispatch('zenith-ui/rootMenu')
      }
    },
    submit() {
      this.$emit('close')
      this.search({ searchText: this.searchText }).then(() => {
        this.$router.push({ path: this.localizedUrl + '/search', query: { search: this.searchText } })
      })
    },
    focusSearch() {
      this.$store.dispatch('zenith-ui/setBodyOverflow')
    }
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    route() {
      return getRouteName(this.$route.name)
    },
    searchOpen () {
      return this.$store.state['zenith-ui'].search.open
    },
    menuOpen () {
      return this.$store.state['zenith-ui'].menu.open
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    },
    currentSection() {
      return this.$store.state['zenith-ui'].menu.item
    }
  },
  mounted() {
    if (window.innerWidth >= 768) {
      this.$refs.input.focus()
    }
    document.addEventListener('keyup', this.keyup)
    // document.addEventListener('click', this.trapFocus, true)
    // document.addEventListener('focus', this.trapFocus, true)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyup)
    // document.removeEventListener('focus', this.trapFocus, true)
    // document.removeEventListener('click', this.trapFocus, true)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.search {
  position: absolute;
  z-index: 20;
  top: 12rem;
  left: 0;
  width: 100%;

  @include bp(max md) {
    position: relative;
    top: 0;
    padding-top: 0;
    z-index: 50;

    &.-active {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }

  &:before {
    content: '';
    width: 100vw;
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translateX(-50%);
    border-bottom: 1px solid var(--zenith-header-border);
    background: var(--zenith-header-background);
  }

  &:after {
    content: '';
    width: 100vw;
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 9rem;
    transform: translateX(-50%);
    background: var(--zenith-header-search-input-block-background);

    @include bp(max md) {
      height: 5rem;
    }
  }

  button {
    position: absolute;
    z-index: 40;
    right: 4rem;
    top: 2.4rem;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    color: var(--zenith-header-search-button);

    @include bp(max md) {
      right: -0.4rem;
      top: 1.2rem;
    }

    i {
      display: block;
      font-size: em(40);
      color: var(--zenith-theme-primary);

      @include bp(max md) {
        font-size: em(28);
      }
    }
  }

  input {
    position: relative;
    z-index: 30;
    border: none;
    width: calc(100% - 4rem);
    background: none;
    outline-offset: 0;
    margin: 2rem;
    padding: 1rem 8rem 0.8rem 2rem;
    transition: $transition-default-fast;
    text-transform: uppercase;
    color: var(--zenith-theme-primary);
    font-size: em(22);
    letter-spacing: em(1.6, 22);
    font-weight: 500;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      font-weight: 500;
      font-size: em(22);
      letter-spacing: em(1.6, 22);
      -webkit-text-fill-color: var(--zenith-theme-primary);
      -webkit-box-shadow: 0 0 0 1000px var(--zenith-header-search-input-block-background) inset;
    }

    @include bp(max md) {
      margin: 0;
      width: 100%;
      font-size: em(14);
      letter-spacing: em(0.18, 14);
      padding: 1.6rem 3rem 1.3rem 0;
    }

    &::placeholder {
      opacity: 1; /* Firefox */
      color: var(--zenith-grey);
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: var(--zenith-grey);
      font-weight: normal;
    }
    &::-ms-input-placeholder {
      color: var(--zenith-grey);
      font-weight: normal;
    }

    &:focus {
      outline: none;

      @include bp(lg) {
        outline: 1px dashed var(--zenith-header-search-input-focus);
        outline-offset: 4px;
        &:hover {
          outline: 1px dashed transparent;
          outline-offset: 0;
        }
      }

      @include bp(max md) {
        & ~ .suggest {
          display: block;
          opacity: 1;
          pointer-events: all;
          transform: translateY(0);
        }
      }
    }
  }

  .suggest {
    overflow-x: hidden;
    padding-top: 4rem;
    padding-bottom: 10rem;
    max-height: calc(100vh - 21rem);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      border-radius: 2px;
      background-color: #DFDFDF;
    }

    &::-webkit-scrollbar-thumb {
      background: #262626;
      border-radius: 2px;
    }

    @include bp(max md) {
      position: absolute;
      display: none;
      background: var(--zenith-header-background);
      margin: 0 -1.5rem;
      max-height: calc(100vh - 14rem);
      transition: all 0.2s ease-in-out;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-2rem);
    }

    i {
      color: var(--zenith-grey);
      display: block;
      margin-bottom: 2rem;
    }
  }

  .collections {
    transition: all 0.4s ease-in-out;
    padding: 0 .5rem;
    margin-bottom: 0;
    list-style: none;
    text-align: center;

    li {
      padding: 0 1rem;
      width: 20%;

      @include bp(max lg) {
        margin-bottom: 2rem;
      }

      @include bp(max md) {
        width: 50%;
      }
    }

    .collection {
      /deep/ a {
        &:before {
          display: none;
        }
        &:hover,
        &:focus {
          .collection-item-image {
            transform: translate(-50%, -50%);
          }
        }
        span,
        strong,
        h3 {
          margin-bottom: 0.4rem;
          font-size: em(16);
          letter-spacing: em(0.2, 16);
          color: var(--zenith-theme-primary);

          @include bp(max md) {
            margin-bottom: 2rem;
          }

          &.h5:not(:last-child) {
            font-size: em(15);
            letter-spacing: em(0.2, 15);
            margin-bottom: 2rem;

            @include bp(max xl) {
              font-size: em(12);
              letter-spacing: em(0.2, 12);
            }

            & + span {
              display: none;
            }
          }

          &.h5:last-child {
            font-weight: bold;
            font-size: em(14);
            letter-spacing: em(2, 14);
            margin-bottom: 2rem;

            @include bp(max md) {
              display: block;
            }
          }

          &:not(.h5):last-child {
            margin-bottom: 2rem;
            font-size: em(10);
            letter-spacing: em(0.17, 10);

            @include bp(max md) {
              display: none;
            }
          }
        }

        &:hover,
        &:focus {
          span {
            // margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
</style>
