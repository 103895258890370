var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"id":"generic-modal","is-open":_vm.isOpen && _vm.settings && _vm.isPageAllowed()},on:{"close":_vm.close}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.settings)?_c('div',{key:"form",staticClass:"text-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"modal-heading col-12"},[(_vm.settings.headline)?_c('h3',{staticClass:"modal-heading__headline",domProps:{"textContent":_vm._s(_vm.settings.headline)}}):_vm._e(),_vm._v(" "),(_vm.settings.title)?_c('h2',{staticClass:"modal-heading__title",domProps:{"innerHTML":_vm._s(_vm.settings.title)}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"row row-wrapper"},[(_vm.settings.image)?_c('div',{staticClass:"col-6 d-none d-md-block image-col"},[_c('div',{staticClass:"image-container"},[_c('lazy-image',{ref:"picture",staticClass:"image-container__image",attrs:{"item":_vm.settings.image,"sizes":"30w","rootMargin":"0px"}})],1)]):_vm._e(),_vm._v(" "),_c('form',{staticClass:"form col-12 col-md-6 offset-md-6 form-col",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.settings)?_c('div',{staticClass:"form-heading",class:{
              'text-left': _vm.settings.textAlignment === 'left',
              'text-center': _vm.settings.textAlignment === 'center',
              'text-right': _vm.settings.textAlignment === 'right'
            },domProps:{"textContent":_vm._s(_vm.settings.text)}}):_vm._e(),_vm._v(" "),_c('base-input',{attrs:{"id":"email","type":"email","name":"email","autocomplete":"email","placeholder":_vm.$t('E-mail address') + ' *',"validations":[
              {
                condition: !_vm.$v.email.required && _vm.$v.email.$error,
                text: _vm.$t('Field is required.')
              },
              {
                condition: !_vm.$v.email.email && _vm.$v.email.$error,
                text: _vm.$t('Please provide valid e-mail address.')
              }
            ],"required":true},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-select',{attrs:{"id":"title","name":"title","autocomplete":"honorific-prefix","placeholder":_vm.$t('Title') + ' *',"validations":[
              {
                condition: _vm.$v.title.$error && !_vm.$v.title.required,
                text: _vm.$t('Field is required.')
              }
            ],"required":true,"options":[
              { value: 'mr', label: _vm.$t('Mr.') },
              { value: 'mrs', label: _vm.$t('Mrs.') }
            ]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_vm._v(" "),(_vm.countryCode === 'JP')?[_c('base-input',{attrs:{"type":"text","id":"surname","name":"surname","autocomplete":"family-name","placeholder":_vm.$t('Last name') + ' *',"validations":[
              {
                condition: !_vm.$v.surname.required && _vm.$v.surname.$error,
                text: _vm.$t('Field is required.')
              }
            ],"required":true},on:{"blur":function($event){return _vm.$v.surname.$touch()}},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_vm._v(" "),_c('base-input',{attrs:{"id":"name","type":"text","name":"name","autocomplete":"given-name","placeholder":_vm.$t('First name') + ' *',"validations":[
              {
                condition: !_vm.$v.name.required && _vm.$v.name.$error,
                text: _vm.$t('Field is required.')
              }
            ],"required":true},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"text","id":"kanjiLastName","name":"kanjiLastName","placeholder":_vm.$t('Local last name')},on:{"blur":function($event){return _vm.$v.kanjiLastName.$touch()}},model:{value:(_vm.kanjiLastName),callback:function ($$v) {_vm.kanjiLastName=$$v},expression:"kanjiLastName"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"text","id":"kanjiFirstName","name":"kanjiFirstName","placeholder":_vm.$t('Local first name')},on:{"blur":function($event){return _vm.$v.kanjiFirstName.$touch()}},model:{value:(_vm.kanjiFirstName),callback:function ($$v) {_vm.kanjiFirstName=$$v},expression:"kanjiFirstName"}})]:[_c('base-input',{attrs:{"id":"name","type":"text","name":"name","autocomplete":"given-name","placeholder":_vm.$t('First name') + ' *',"validations":[
              {
                condition: !_vm.$v.name.required && _vm.$v.name.$error,
                text: _vm.$t('Field is required.')
              }
            ],"required":true},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"text","id":"surname","name":"surname","autocomplete":"family-name","placeholder":_vm.$t('Last name') + ' *',"validations":[
              {
                condition: !_vm.$v.surname.required && _vm.$v.surname.$error,
                text: _vm.$t('Field is required.')
              }
            ],"required":true},on:{"blur":function($event){return _vm.$v.surname.$touch()}},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],_vm._v(" "),_c('div',{staticClass:"toggle-line",class:{
              '-error': !_vm.$v.conditions.required && _vm.$v.conditions.$error
            }},[_c('Toggle',{attrs:{"id":"conditions","required":true},model:{value:(_vm.conditions),callback:function ($$v) {_vm.conditions=$$v},expression:"conditions"}}),_vm._v(" "),_c('NoSSR',[_c('label',{staticClass:"-small",attrs:{"for":"conditions"},domProps:{"innerHTML":_vm._s(
                  _vm.$t('I accept the terms of use', {
                    href: _vm.localizedUrl + '/page/terms'
                  })
                )}},[_vm._v("*")])]),_vm._v(" "),_c('div',{staticClass:"form-error"},[_c('label',{attrs:{"for":"conditions"}},[_vm._v(_vm._s(_vm.$t('Field is required.')))])])],1),_vm._v(" "),_c('div',{staticClass:"toggle-line"},[_c('Toggle',{attrs:{"id":"is_subscribed"},model:{value:(_vm.is_subscribed),callback:function ($$v) {_vm.is_subscribed=$$v},expression:"is_subscribed"}}),_vm._v(" "),_c('label',{staticClass:"-small",attrs:{"for":"is_subscribed"}},[_vm._v(_vm._s(_vm.$t('I would like to sign up for the newsletter')))])],1),_vm._v(" "),_c('div',{staticClass:"recaptcha",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
                {
                  hrefPrivacy: 'https://policies.google.com/privacy',
                  hrefTerms: 'https://policies.google.com/terms'
                }
              )
            )}}),_vm._v(" "),_c('CustomLoader',{attrs:{"is-loading":_vm.loading}},[(_vm.settings.ctaLabel)?_c('button',{staticClass:"btn",class:{
                '-custom-bg-color': _vm.settings.textsColor !== this.DEFAULT_TEXT_COLOR
              },style:(_vm.buttonStyle),attrs:{"type":"submit"},domProps:{"textContent":_vm._s(_vm.settings.ctaLabel)}}):_c('button',{staticClass:"btn",attrs:{"type":"submit"}},[_vm._v("\n              "+_vm._s(_vm.$t('Send'))+"\n            ")])])],2)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }