<template>
  <NoSSR>
  <div :class="className">
    <div class="top-msg -active" v-if="active">
      <div class="container">
        <Hooper :settings="hooperSettings" tabindex="-1">
          <Slide v-if="block.item.text && this.block.item.text.length > 7">
            <template v-if="block.item.expand !== '<p></p>'">
              <div class="-small">
                <p class="text" v-html="block.item.text"></p>
                <button
                  class="toggle"
                  type="button"
                  @click="expand = !expand"
                  :aria-expanded="expand ? 'true' : 'false'"
                  aria-controls="top-msg-expand"
                  v-text="expand ? $t('Less') : $t('More')"
                ></button>
                <div
                  id="top-msg-expand"
                  class="expand"
                  :class="{ '-active': expand }"
                  v-html="block.item.expand"
                ></div>
              </div>
            </template>
            <template v-else>
              <div class="text -small" v-html="block.item.text"></div>
            </template>
          </Slide>
          <Slide v-if="!this.isNewUser && block.item.returningUserMsg.length > 7">
            <div class="text -small" v-html="block.item.returningUserMsg"></div>
          </Slide>
        </Hooper>
      </div>
      <button
        type="button"
        class="close"
        @click="active = false"
        :aria-label="$t('Close top message')"
      >
        <i class="icon-close"></i>
      </button>
    </div>
  </div>
</NoSSR>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ReadyHook } from 'vue-asyncready'
import { Hooper, Slide } from 'hooper'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
const HIDE_AFTER_TOT_DAYS = 1
const SEC_PER_DAY = 1000 * 60 * 60 * 24

export default {
  name: 'TopMessage',
  props: ['className'],
  mixins: [ReadyHook],
  components: {
    Hooper,
    Slide,
    NoSSR
  },
  computed: {
    ...mapGetters('prismic', ['getBlock'])
  },
  methods: {
    ...mapActions('prismic', ['setBlock'])
  },
  data() {
    return {
      isNewUser: true,
      block: null,
      active: false,
      expand: false,
      uid: 'top-message',
      hooperSettings: {
        transition: 1000,
        playSpeed: 5000,
        autoPlay: true,
        mouseDrag: true,
        shortDrag: true,
        touchDrag: true,
        hoverPause: true,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        centerMode: true
      }
    }
  },
  async created() {
    this.block = this.getBlock(this.uid)
    if (!this.block) {
      await this.setBlock(this.uid)
      this.block = this.getBlock(this.uid)
    }
    if (!isServer) {
      const lastViewed = Vue.prototype.$cookies.get(`Zmlyc3RfdmlzaXQ=2`)
      if (lastViewed) {
        this.isNewUser =
          Date.now() < Number(lastViewed) + HIDE_AFTER_TOT_DAYS * SEC_PER_DAY
      } else {
        Vue.prototype.$cookies.set(`Zmlyc3RfdmlzaXQ=2`, Date.now())
      }
    }
    this.active = !!(this.block && this.block.item && this.block.item.text && this.block.item.text.length > 7) ||
    !!(this.block && this.block.item && this.block.item.returningUserMsg && !this.isNewUser && this.block.item.returningUserMsg.length > 7)
  },
  async serverPrefetch() {
    await this.setBlock(this.uid)
    this.block = this.getBlock(this.uid)
    this.active = !!(this.block && this.block.item && this.block.item.text)
  }
  // async created() {
  //   console.log('flag-before-created', this.active)
  //   console.log('flag-before-created', this.block.item.text)
  //   await this.setBlock(this.uid)
  //     this.block = this.getBlock(this.uid)
  //     console.log('flag-after-set-block', this.block)
  //     if (this.block && this.block.item && this.block.item.text) {
  //       this.active = true
  //       console.log('flag-after-created', this.active)
  //       console.log('flag-after-created', this.block.item.text)
  //     }
  // },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.top-msg {
  position: relative;
  z-index: 100;
  background: var(--zenith-theme-primary);
  color: var(--zenith-white);
  text-align: center;
  overflow: hidden;
  padding: 0;
  max-height: 0;
  transition: all 0.4s ease-in-out;

  .hooper {
    height: auto !important;
    &-track {
      align-items: center;
    }
    &-slide {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.-active {
    max-height: 40rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .container {
    position: relative;

    div.-small:not(.text) {
      /deep/ p {
        color: var(--zenith-white);
        display: inline-block;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      /deep/ a {
        position: relative;
        display: inline-block;
        color: var(--zenith-white);

        &:before {
          position: absolute;
          content: '';
          display: block;
          height: 0.1rem;
          width: 100%;
          background: var(--zenith-white);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          &:before {
            width: 0;
          }
        }
      }

      div.expand {
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s ease-in-out;

        /deep/ a,
        /deep/ p {
          color: var(--zenith-white);
          padding: 0;
          margin: 0;
          text-transform: none;
        }

        /deep/ p {
          padding-top: 1rem;
        }

        &.-active {
          max-height: 10rem;
        }
      }
    }

    div.-small.text {
      /deep/ a {
        position: relative;
        display: inline-block;
        color: var(--zenith-white);

        &:before {
          position: absolute;
          content: '';
          display: block;
          height: 0.1rem;
          width: 100%;
          background: var(--zenith-white);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          &:before {
            width: 0;
          }
        }
      }
      /deep/ p:last-child {
        margin-bottom: 0;
      }
    }
  }

  button.toggle {
    position: relative;
    display: inline-block;
    color: var(--zenith-white);
    padding: 0;
    margin-left: 0.4rem;
    border: none;
    background: none;
    text-transform: uppercase;

    &:before {
      position: absolute;
      content: '';
      display: block;
      height: 0.1rem;
      width: 100%;
      background: var(--zenith-white);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      &:before {
        width: 0;
      }
    }
  }

  button.close {
    position: absolute;
    right: 4rem;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--zenith-white);

    @media (max-width: 1300px) {
      right: 2rem;
    }

    @include bp(max md) {
      right: 1rem;
    }

    i {
      display: block;
      font-size: em(20, 12);
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      i {
        transform: scale(1.2);
      }
    }
  }
}
</style>
